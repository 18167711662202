import React, { useEffect, useState, useCallback } from "react";
import Input from "components/Inputs/Input";
import MaskedInput from "components/Inputs/MaskedInput";
import Select from "components/Inputs/Select";
import Checkbox from "components/Inputs/Checkbox";
import { useTranslation } from "react-i18next";
import { WithWizard, Wizard } from "react-albus";
import { useForm, FieldError } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as RegistrationActions from "store/actions/RegistrationActions";
import generateCompanyDataSchema, { generateRegonSchema } from "./CompanyDataValidation";
import { isNipTaken } from "store/thunk/RegistrationThunk";
import { initialCompanyData } from "store/reducers/RegistrationReducer";
import { NipValidator } from "utils";
import { Spinner } from "react-bootstrap";
import classNames from "classnames";
import { debounce } from "../../../utils/debounce";

interface CompanyDataProps {
  nextStep: () => void;
  prevStep: () => void;
  formData: any;
}

export const companyProfileOptions = [
  { value: "online_sale", option: "Sprzedaż online" },
  { value: "offline_sale", option: "Sprzedaż offline" },
  { value: "marketplace", option: "Platforma sprzedażowa typu marketplace" },
  { value: "logistic_services", option: "Usługi logistyczne" },
  { value: "it_company", option: "Firma IT: aplikacje webowe i/lub mobilne" },
  { value: "university", option: "Szkoła/uczelnia wyższa" },
  { value: "research_agency", option: "Agencja/instytucja badawcza" },
  { value: "product_catalog", option: "Katalog produktowy" },
  { value: "other", option: "Inne" },
];

type RegistrationPurpose = {
  value: string;
  option: string;
};

export const registrationPurposeOptions: RegistrationPurpose[] = [
  { value: "checkSingleGtin", option: "Chcę wyszukać i sprawdzić kod EAN" },
  { value: "checkMultipleGtins", option: "Chcę zbiorczo sprawdzić wiele kodów EAN" },
  { value: "useSearch", option: "Chcę skorzystać z wyszukiwarki produktów" },
  { value: "downloadDatabase", option: "Chcę pobrać bazę produktów" },
  {
    value: "checkMyGtins",
    option: "Mam swoje kody (jestem klientem GS1) – chcę zobaczyć swoje produkty na platformie",
  },
];

const districtOptions = [
  { value: "Dolnośląskie", option: "Dolnośląskie" },
  { value: "Kujawsko-Pomorskie", option: "Kujawsko-Pomorskie" },
  { value: "Lubelskie", option: "Lubelskie" },
  { value: "Lubuskie", option: "Lubuskie" },
  { value: "Łódzkie", option: "Łódzkie" },
  { value: "Małopolskie", option: "Małopolskie" },
  { value: "Mazowieckie", option: "Mazowieckie" },
  { value: "Opolskie", option: "Opolskie" },
  { value: "Podkarpackie", option: "Podkarpackie" },
  { value: "Podlaskie", option: "Podlaskie" },
  { value: "Pomorskie", option: "Pomorskie" },
  { value: "Śląskie", option: "Śląskie" },
  { value: "Świętokrzyskie", option: "Świętokrzyskie" },
  { value: "Warmińsko-Mazurskie", option: "Warmińsko-Mazurskie" },
  { value: "Wielkopolskie", option: "Wielkopolskie" },
  { value: "Zachodniopomorskie", option: "Zachodniopomorskie" },
];

const CompanyData: React.FC<CompanyDataProps> = ({ nextStep = () => {}, prevStep, formData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { disableCompanyDataForm, regonChecked, nipInUse } = useSelector((state: any) => state.registrationForm);
  const { handleSubmit, register, errors, triggerValidation, getValues, setValue } = useForm({
    validationSchema: disableCompanyDataForm ? generateRegonSchema(t) : generateCompanyDataSchema(t),
    mode: "onChange",
    defaultValues: formData,
  });

  const [nipError, setNipError] = useState<FieldError | undefined>();
  const [isNipPending, setIsNipPending] = useState<boolean>(false);

  const CbOnSubmit = useCallback(
    async (data: any) => {
      onSubmit(data);
    },
    [nipInUse],
  );

  /**
   * Callback to go to next step in registration process
   * @param data
   */
  async function onSubmit(data: any) {
    if (disableCompanyDataForm || !nipInUse) {
      dispatch(RegistrationActions.updateRegistrationCompanyData(data));
      nextStep();
    }
  }

  /**
   * Go to prev step and save data to redux state (remember it)
   */
  function goToPrevStep() {
    const data: any = getValues({ nest: true });
    dispatch(RegistrationActions.updateRegistrationCompanyData(data));
    prevStep();
  }

  const checkNipDebounce = debounce(
    "checkNip",
    async function() {
      await checkNip();
    },
    1500,
  );

  /**
   * Checks nip number for errors
   */
  async function checkNip() {
    const { nip } = getValues({ nest: true });
    const isValid = NipValidator().validate(nip);

    if (isValid) {
      const taken: any = await dispatch(isNipTaken(nip));
      if (taken) {
        dispatch(RegistrationActions.isNipTaken(true));
        setNipError({
          type: "error",
          message: t("registration.form.companyData.nip.isTaken"),
        });
      } else {
        setNipError(undefined);
        dispatch(RegistrationActions.isNipTaken(false));
      }
    } else {
      setNipError(undefined);
      // dispatch(updateRegistrationUserData(clearPersonalForm)); For when is not valid nip
    }
    setIsNipPending(false);
  }

  useEffect(() => {
    const { regon, ...data } = formData;
    Object.entries(data).forEach((prop) => setValue(prop[0], prop[1]));
  }, [formData]);

  return (
    <Wizard>
      <WithWizard
        render={() => (
          <form onSubmit={handleSubmit(CbOnSubmit)}>
            <div className="company-form">
              <div className="col-12 mb-3">
                <h2>{t("form.steps.companyData")}</h2>
              </div>
              <div className="col-md-6 mb-3 pr-3">
                <h3>{t("company")}</h3>
                <Input
                  labelText={t("registration.form.companyData.name")}
                  name="name"
                  placeholder=" "
                  tabIndex={1}
                  error={errors.name}
                  disabled={disableCompanyDataForm}
                  reference={register}
                />
                <Input
                  labelText={t("registration.form.companyData.nip")}
                  name="nip"
                  error={nipError ? { ...errors.nip, ...nipError } : errors.nip}
                  errorTestId="nip-input-error"
                  tabIndex={3}
                  placeholder=" "
                  handleChange={() => {
                    setIsNipPending(true);
                    checkNipDebounce();
                  }}
                  inputIcon={isNipPending && <Spinner className="ml-2 spinner" animation="border" size="sm" />}
                  disabled={disableCompanyDataForm}
                  reference={register}
                />
                <Input
                  labelText={
                    t("registration.form.companyData.webPage") + t("registration.form.companyData.webPage.optional")
                  }
                  name="webPage"
                  placeholder=" "
                  error={errors.webPage}
                  tabIndex={6}
                  reference={register}
                  disabled={disableCompanyDataForm}
                />
                <Select
                  labelText={t("registration.form.companyData.companyProfile")}
                  name="companyProfile"
                  error={errors.companyProfile}
                  placeholder=" "
                  tabIndex={8}
                  reference={register}
                  disabled={!regonChecked && disableCompanyDataForm}
                  options={companyProfileOptions}
                />
                <div className={classNames("mb-0 select registration-purpose")}>
                <label className={classNames("select-label", {"error": errors.registrationPurpose && !Array.isArray(errors.registrationPurpose) && errors.registrationPurpose.message})}>
                    Cel rejestracji
                    {errors.registrationPurpose && !Array.isArray(errors.registrationPurpose) && errors.registrationPurpose.message && (
                        <p className="error">{errors.registrationPurpose.message}</p>
                    )}
                </label>
                  {registrationPurposeOptions.map((purpose, index) => (
          <div key={index}>
            <Checkbox
            tabIndex={10+index}
              reference={register}
              name={`registrationPurpose_${purpose.value}`}>
              {purpose.option}
            </Checkbox>
          </div>
        ))}
                </div>
              </div>
              <div className="col-md-6 mb-3 pl-3">
                <h3>{t("address")}</h3>
                <Input
                  labelText={t("registration.form.companyData.street")}
                  name="street"
                  placeholder=" "
                  error={errors.street}
                  tabIndex={2}
                  reference={register}
                  disabled={disableCompanyDataForm}
                />
                <div className="row">
                  <div className="col-5">
                    <MaskedInput
                      labelText={t("registration.form.companyData.postalCode")}
                      name="postalCode"
                      error={errors.postalCode}
                      defaultValue={formData.postalCode}
                      mask="00-000" // Format must be like 00-000
                      formHooks={{ register, setValue }} // Hooks required wih masks, form loose reference after wrapping input by mask component
                      tabIndex={4}
                      reference={register}
                      disabled={disableCompanyDataForm}
                    />
                  </div>
                  <div className="col-7">
                    <Input
                      labelText={t("registration.form.companyData.city")}
                      name="city"
                      error={errors.city}
                      tabIndex={5}
                      reference={register}
                      placeholder=" "
                      disabled={disableCompanyDataForm}
                    />
                  </div>
                </div>

                <Select
                  labelText={t("registration.form.companyData.district")}
                  name="district"
                  error={errors.district}
                  reference={register}
                  tabIndex={7}
                  placeholder=""
                  disabled={disableCompanyDataForm}
                  options={districtOptions}
                />
                <Input
                  labelText={t("registration.form.companyData.country")}
                  name="country"
                  value="Polska"
                  error={errors.country}
                  reference={register}
                  disabled={true}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn secondary mr-2 px-5 "
                onClick={() => {
                  dispatch(
                    RegistrationActions.updateRegistrationCompanyData({ ...initialCompanyData, regon: formData.regon }),
                  );
                  dispatch(RegistrationActions.setRegonChecked(false));
                  Object.keys(initialCompanyData).map((key: string) => {
                    if (key !== "country") setValue(key, "", false);
                  }); // clear out the form when user leaves the view and MIGHT change REGON
                  goToPrevStep();
                }}
                type="button">
                {t("back")}
              </button>
              <button
                disabled={isNipPending}
                data-testid="next"
                tabIndex={9}
                className={classNames("btn primary margin-1-b px-5", { disabled: isNipPending })}
                type="submit">
                {t("next")}
              </button>
            </div>
          </form>
        )}
      />
    </Wizard>
  );
};

export default CompanyData;
