import React, { useEffect, useState, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "../Tooltip/Tooltip";
import { LimitLeftData } from "../../store/actions/RegistrationActions";
import useMessage from "hooks/useMessageStatus";
import NPWSStatus from "utils/statuses";

interface DownloadDBButtonProps {
  disabled?: boolean;
  company?: any;
  getDatabase: () => void;
  allProducts?: number;
  showWarningOverLimit?: boolean;
}

const DownloadDBButton: React.FC<DownloadDBButtonProps> = ({ 
  disabled,
  company,
  getDatabase,
  allProducts,
  showWarningOverLimit,
}) => {
  const { t } = useTranslation();

  let styleButton: CSSProperties = {};
  if (disabled) {
    styleButton.pointerEvents = "none";
  }

  const styleDivWrapper: CSSProperties = {
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    cursor: disabled ? "not-allowed" : "pointer",
  };

  const overLimitText = "Wybrana ilość produktów przekracza limit w Twoim pakiecie. Ogranicz wyniki wyszukiwania lub zwiększ pakiet.";

  const handleButtonClick = () => {
    if (!showWarningOverLimit) {
      getDatabase(); // Call the database function if within limits
    }
  };

  return (
    <div style={styleDivWrapper}>
      {showWarningOverLimit && <span></span>}
      {showWarningOverLimit ? (
        <Tooltip tooltipText={overLimitText}>
          <button
            className="btn border bg-color-white txt-color-dusty-orange"
            disabled={disabled}
            aria-label={overLimitText}
            type="button"
            onClick={handleButtonClick}
            style={styleButton}
          >
            <i className="fas fa-download mr-3"></i>
            {t("downloadDatabase")}
          </button>
        </Tooltip>
      ) : (
        <button
          className="btn border bg-color-white txt-color-dusty-orange"
          disabled={disabled}
          type="button"
          onClick={handleButtonClick}
          style={styleButton}
        >
          <i className="fas fa-download mr-3"></i>
          {t("downloadDatabase")}
        </button>
      )}
    </div>
  );
};

export default DownloadDBButton;
